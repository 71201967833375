<template>
  <div class="h-full">
    <div
      :class="{ 'mdc-linear-progress--closed': !$store.state.loading }"
      class="mdc-linear-progress mdc-linear-progress--indeterminate z-50 fixed"
      role="progressbar"
    >
      <div class="mdc-linear-progress__buffering-dots" />
      <div class="mdc-linear-progress__buffer" />
      <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
        <span class="mdc-linear-progress__bar-inner" />
      </div>
      <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
        <span class="mdc-linear-progress__bar-inner" />
      </div>
    </div>

    <aside ref="drawer" class="mdc-drawer mdc-drawer--modal">
      <router-link
        to="/"
        class="mdc-drawer__header"
        @click.native="mdcDrawer.open = false"
      >
        <svg-logo class="w-48 pt-2" />
      </router-link>
      <div class="mdc-drawer__content">
        <nav class="mdc-list">
          <router-link
            v-if="$store.state.isLoggedIn"
            to="/email"
            class="mdc-list-item"
            active-class="mdc-list-item--activated"
            @click.native="mdcDrawer.open = false"
          >
            <i
              class="material-icons mdc-list-item__graphic mirrorX"
              aria-hidden="true"
              >dashboard</i
            >
            <span class="mdc-list-item__text">Dashboard</span>
          </router-link>
          <template v-else>
            <router-link
              to="/login"
              class="mdc-list-item"
              active-class="mdc-list-item--activated"
              @click.native="mdcDrawer.open = false"
            >
              <i
                class="material-icons mdc-list-item__graphic mirrorX"
                aria-hidden="true"
                >assignment_return</i
              >
              <span class="mdc-list-item__text" v-text="'Log In'" />
            </router-link>
            <router-link
              to="/register"
              class="mdc-list-item"
              active-class="mdc-list-item--activated"
              @click.native="mdcDrawer.open = false"
            >
              <i
                class="material-icons mdc-list-item__graphic"
                aria-hidden="true"
                >assignment</i
              >
              <span class="mdc-list-item__text" v-text="'Register'" />
            </router-link>
          </template>
        </nav>
      </div>
    </aside>

    <div class="mdc-drawer-scrim" />

    <div class="mdc-drawer-app-content h-full">
      <header
        ref="header"
        class="mdc-top-app-bar mdc-top-app-bar--fixed bg-white"
      >
        <div class="mdc-top-app-bar__row max-w-screen-xl mx-auto">
          <section
            class="
              mdc-top-app-bar__section mdc-top-app-bar__section--align-start
            "
          >
            <span class="mdc-top-app-bar__title">
              <router-link to="/">
                <svg-logo class="text-gray-darker w-48 pt-2" />
              </router-link>
            </span>
          </section>
          <section
            class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
          >
            <span class="hidden sm:block mr-8">
              <mdc-button
                v-if="$store.state.isLoggedIn"
                @click.native="$router.push('/email')"
                >Dashboard</mdc-button
              >
              <template v-else>
                <mdc-button
                  ripple
                  class="mr-4"
                  @click.native="$router.push('/login')"
                  >Log In</mdc-button
                >
                <mdc-button ripple @click.native="$router.push('/register')"
                  >Sign Up</mdc-button
                >
              </template>
            </span>
            <span
              class="
                sm:hidden
                material-icons
                cursor-pointer
                text-gray-dark
                hover:text-black
                mr-4
              "
              @click="mdcDrawer.open = true"
              >menu</span
            >
          </section>
        </div>
      </header>

      <main
        ref="main"
        class="
          mdc-top-app-bar--fixed-adjust
          w-fulsl
          h-full
          overflow-auto
          flex flex-col
        "
      >
        <div class="flex-1">
          <router-view />
        </div>

        <footer
          class="bg-primary text-sm text-center p-4 mt-auto flex-no-shrink"
        >
          Copyright &copy;
          {{
            this.$store.state.currentTime
              ? this.$store.state.currentTime.year
              : new Date().getFullYear()
          }}
          Later.io<span class="hidden sm:inline"> All rights reserved</span>.
          <router-link class="text-gray-lighter mx-2" to="/terms">
            Terms<span class="hidden sm:inline"> of Service</span>
          </router-link>
        </footer>
      </main>
    </div>

    <mdc-snackbar
      :value="$store.state.snackbarMessage"
      @input="$store.commit('snackbarMessage', null)"
    />
  </div>
</template>

<script>
import MdcButton from '@/components/mdc/button.vue';
import MdcSnackbar from '@/components/mdc/snackbar.vue';
import { MDCDrawer } from '@material/drawer';
import { MDCTopAppBar } from '@material/top-app-bar';
import SvgLogo from '@/svg/logo.svg';

export default {
  components: {
    MdcButton,
    MdcSnackbar,
    SvgLogo,
  },

  data: () => ({
    mdcTopAppBar: null,
    mdcDrawer: null,
  }),

  mounted() {
    this.mdcTopAppBar = new MDCTopAppBar(this.$refs.header);
    this.mdcTopAppBar.setScrollTarget(this.$refs.main);
    this.mdcDrawer = new MDCDrawer(this.$refs.drawer);

    if (this.$store.state.snackbarMessage) {
      this.$store.commit(
        'snackbarMessage',
        `${this.$store.state.snackbarMessage} `
      );
    }
  },

  beforeDestroy() {
    this.mdcTopAppBar.destroy();
    this.mdcDrawer.destroy();
  },
};
</script>
